.LoadClocks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: linear-gradient(
    181deg,
    hsla(360, 100%, 50%, 0.5),
    5%,
    hsla(240, 100%, 50%, 0.5),
    95%,
    hsla(240, 100%, 100%, 0.5)
  );
  z-index: 2;
}

.NoLoadsScheduled {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  text-align: center;
  text-shadow: 0px 0px 2px red;
  border: 3px solid white;
  border-radius: 10px;
  padding: 1em;
  background: hsla(255, 100%, 10%, 0.25);
  box-shadow: 1px 1px 10px hsl(255, 100%, 10%);
}

.Load {
  display: flex;
  flex-direction: column;
  margin: 1em;
  width: calc(100% - 10px - 2em);
  background: linear-gradient(
    hsla(0, 100%, 75%, 0.5),
    1%,
    hsla(240, 100%, 10%, 0.5)
  );
  border: 3px solid white;
  border-radius: 10px;
}
.Load.yellow {
  border-color: yellow;
}
.Load.orange {
  border-color: orange;
}
.Load.red {
  border-color: red;
}
.Load header {
  text-align: center;
  font-size: 3em;
  font-weight: bold;
  border-bottom: 3px solid white;
  flex: 0;
}
.Load header.status-red {
  background-color: red;
}
.Load header.status-blue {
  background-color: blue;
}
.Load span.time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16em;
  color: white;
  text-shadow: -1px -1px 2px white, 2px 2px 5px black;
  opacity: 0.85;
}
.Load span.time.yellow {
  color: yellow;
}
.Load span.time.orange {
  color: orange;
}
.Load span.time.red {
  color: red;
}
.Load span.json {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  white-space: pre;
  overflow: scroll;
}
.Load footer {
  flex: 0;
  font-weight: bold;
  text-align: center;
  font-size: 3em;
}
.Load > * {
  height: 100%;
}

@media only screen and (max-width: 720px) {
  .Load {
    header {
      font-size: 3em;
    }
    span.time {
      font-size: 10em;
    }
    footer {
      font-size: 2em;
    }
  }
}
@media only screen and (max-width: 768px) {
  .Load header {
    font-size: 3em;
  }
  .Load span.time {
    font-size: 10em;
  }
  .Load footer {
    font-size: 2em;
  }
}
@media only screen and (max-height: 1080px) {
  .Load header {
    font-size: 2em;
  }
  .Load span.time {
    font-size: 8em;
  }
  .Load footer {
    font-size: 1em;
  }
}
@media only screen and (max-width: 1080px) {
  .LoadClocks::after {
    font-size: 4em;
  }
}
@media only screen and (max-width: 640px) {
  .Load header {
    font-size: 1.5em;
  }
  .Load span.time {
    font-size: 4em;
  }
}
