.Footer {
  position: relative;
  bottom: 0;
  height: 15vh;
}
.Footer.fakeData {
}
.Footer.fakeData::before {
  position: absolute;
  top: 0;
  z-index: 30;
  color: white;
  font-weight: bold;
  color: yellow;
  border: 2px solid greenyellow;
  content: "Note: NOT LIVE DATA!";
}
.Footer.fakeData.Compass .Face .Arrow.previous {
  transition: none;
  display: none;
}
