.App {
  display: flex;
  width: 100vw;
  color: white;
  background-color: black;
}
@media only screen and (max-width: 500px) {
  .App {
    background-size: contain;
  }
}
