.Compass {
  position: relative;
  width: 15vh;
  height: 15vh;
  z-index: 10;
  font-size: 2vh;
  border-radius: 10px 0 0 10px;
}
.Compass .Face {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.Compass .Face .Circle {
  position: relative;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  font-size: 4vh;
  font-weight: bold;
  text-shadow: 2px 1px 3px black, 0px -1px white;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(
    hsla(240, 100%, 50%, 0.25),
    66%,
    hsla(240, 100%, 10%, 0.25),
    90%,
    hsla(300, 100%, 10%, 1)
  );
  clip-path: circle(40% at center);
}
.Compass .Face .LabelGrid {
  font-weight: bold;
  color: hsla(240, 100%, 75%, 0.75);
  text-shadow: 0px 0px 2px black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Compass .Face .LabelGrid .grid-cell {
  position: absolute;
}
.Compass .Face .LabelGrid .grid-cell.n,
.Compass .Face .LabelGrid .grid-cell.s {
  left: calc(50% - 0.5rem);
}
.Compass .Face .LabelGrid .grid-cell.e,
.Compass .Face .LabelGrid .grid-cell.w {
  top: calc(50% - 1rem);
}
.Compass .Face .LabelGrid .grid-cell.e {
  right: 2%;
}
.Compass .Face .LabelGrid .grid-cell.s {
  bottom: 1%;
}
.Compass .Face .LabelGrid .grid-cell.w {
  left: 3%;
}
.Compass .Face .Arrow {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(hsl(60, 100%, 50%), hsl(0, 100%, 50%));
  clip-path: polygon(50% 0, 40% 33%, 50% 50%, 60% 33%);
}
.Compass .Face .Arrow.previous {
  z-index: 0;
  background-image: linear-gradient(hsl(240, 33%, 50%), hsl(0, 33%, 50%));
  clip-path: polygon(50% 0, 40% 33%, 50% 30%, 60% 33%);
  transition: none;
}
.Compass .Face .Hashmark {
  background-color: hsla(240, 100%, 75%, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  clip-path: polygon(47% 0, 53% 0, 50% 3%);
}
