.Screen,
.Settings {
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  background-color: #00007f;
  background: hsl(240, 100%, 25%) url(../SkydiveSpacelandLogo-white-text.png)
    no-repeat center calc(50vh - 132px);
}
.Screen::after,
.Settings::after {
  font-family: "HelveticaNeue";
  position: absolute;
  z-index: 1;
  top: 5vh;
  width: 100%;
  content: attr(locationname);
  font-size: 8em;
  opacity: 0.25;
}
@media only screen and (max-width: 1024px) {
  .Screen::after {
    font-size: 6em;
  }
}
@media only screen and (max-width: 640px) {
  .Screen {
    background-size: contain;
  }
  .Screen::after {
    font-size: 3em;
  }
}
