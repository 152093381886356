/* body, */
html {
  overflow: hidden;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  height: 0px;
}
body {
  /* position: relative; */
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "HelveticaNeue";
  src: url(./HelveticaNeue-HeavyExtObl.woff);
}
