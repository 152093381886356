.WindsAloft {
  font-size: 2vh;
  position: absolute;
  top: 75vh;
  height: 10vh;
  width: 100vw;
  display: flex;
  align-items: center;
  color: gold;
  font-weight: bold;
  text-shadow: -2px -2px 3px black, 2px 2px 3px black;
  opacity: 0.95;
}
.WindsAloft .altitudeGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
.WindsAloft .altitudeGroup .arrow {
  z-index: 1;
  position: absolute;
  width: 10vh;
  height: 10vh;
  clip-path: polygon(
    20% 0%,
    80% 0%,
    80% 40%,
    100% 40%,
    50% 100%,
    0% 40%,
    20% 40%,
    20% 42%,
    4% 42%,
    50% 97%,
    96% 42%,
    78% 42%,
    78% 2%,
    22% 2%,
    22% 42%,
    20% 42%
  );
}
